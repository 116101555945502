import { AxiosInstance } from "axios";
import axios from "axios";
import ClientAPI from "./ClientAPI";

export default class EmployeeTimeService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getEmployeeTimeByID(
    client: string,
    id: string,
    fieldnames: string,
    correls: string,
  ) {
    return ClientAPI.Get(`employeetime/${id}`, {
      Client: client,
      fieldnames,
      correls
    })
  }

  
}
